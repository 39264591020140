/**
 * Copyright 2016, Sourcepole AG.
 * All rights reserved.
 *
 * This source code is licensed under the BSD-style license found in the
 * LICENSE file in the root directory of this source tree.
 */

const React = require("react");
const logo = require("../assets/img/logo.svg");
const Message = require("qwc2/components/I18N/Message");
const ConfigUtils = require("qwc2/utils/ConfigUtils");

const styles = {
    container: {
        display: "flex",
        padding: 10,
        flexWrap: "wrap",
        textAlign: "left",
    },
    logo: {
        marginLeft: 10,
        marginBottom: 10,
    },
    fullspace: {
        width: "100%",
        margin: 0,
        marginBottom: 10,
    },
};

function renderHelp() {
    return (
        <div style={styles.container}>
            <img style={styles.logo} src={logo}></img>
            <p style={styles.fullspace}>
                <Message msgId="help.general_text_data" />
                <br />
                <Message msgId="help.general_text_cart" />
            </p>
            <p style={styles.fullspace}>
                <a
                    target="_blank"
                    href={ConfigUtils.getConfigProp("manualLinkHelp")}
                >
                    <Message msgId="help.info_link_label"></Message>
                </a>
            </p>
            <p style={styles.fullspace}>
                Geoportale{" "}
                <a target="_blank" href="https://www.bbt-se.com/">
                    BBT-SE
                </a>
                <br></br>
                {ConfigUtils.getConfigProp("urlGetCapabilitesLink") && (
                    <a
                        target="_blank"
                        href={`${ConfigUtils.getConfigProp("urlGetCapabilitesLink")}?SERVICE=WMS&VERSION=1.3.0&REQUEST=GetCapabilities`}
                    >
                        Get capabilites
                    </a>
                )}
            </p>
            <p style={styles.fullspace}>
                <Message msgId="help.contact_label" />:<br />
                <a href="mailto:bbt@bbt-se.com">bbt@bbt-se.com</a>
                <br />
                <a
                    target="_blank"
                    href="https://www.bbt-se.com/it/informazione/contattaci/"
                >
                    www.bbt-se.com
                </a>
            </p>
        </div>
    );
}

module.exports = renderHelp;
