/**
 * Copyright 2020, Sourcepole AG.
 * All rights reserved.
 *
 * This source code is licensed under the BSD-style license found in the
 * LICENSE file in the root directory of this source tree.
 */

const React = require('react');
const PropTypes = require('prop-types');
const {connect} = require('react-redux');
const isEmpty = require('lodash.isempty');
const axios = require('axios');
const {removeLayer, addLayerFeatures, removeLayerFeatures} = require('qwc2/actions/layers');
const {zoomToExtent} = require('qwc2/actions/map');
const {setCurrentTask} = require('qwc2/actions/task');
const ResizeableWindow = require("qwc2/components/ResizeableWindow");
const {TaskBar} = require('qwc2/components/TaskBar');
const Spinner = require('qwc2/components/Spinner');
const Message = require("qwc2/components/I18N/Message");
const ConfigUtils = require("qwc2/utils/ConfigUtils");
const LocaleUtils = require("qwc2/utils/LocaleUtils");
const MapUtils = require("qwc2/utils/MapUtils");
require('./style/BBTDistanceTool.css');


class BBTDistanceTool extends React.Component {
    static propTypes = {
        point: PropTypes.object,
        clickFeature: PropTypes.object,
        mapCrs: PropTypes.string,
        enabled: PropTypes.bool,
        setCurrentTask: PropTypes.func,
        removeLayer: PropTypes.func,
        addLayerFeatures: PropTypes.func,
        removeLayerFeatures: PropTypes.func,
        zoomToExtent: PropTypes.func
    }
    state = {
        querying: false,
        data: null,
        error: ""
    }
    static contextTypes = {
        messages: PropTypes.object
    }
    componentWillReceiveProps(newProps) {
        let point = null;
        if(newProps.enabled) {
            point = this.queryPoint(newProps);
        }
        if(point) {
            this.setState({data: null, querying: true, error: ""});
            this.props.removeLayer("bbtdistances");
            const bbtService = ConfigUtils.getConfigProp("bbtService");
            let params = {
                x: point[0],
                y: point[1],
                crs: this.props.mapCrs
            }
            axios.get(bbtService, {params}).then(response => {
                if(response.data.results) {
                    this.setState({data: response.data, querying: false});
                    this.drawMeasurementLines(response.data);
                } else {
                    this.setState({error: response.data.error || "", querying: false});
                }
            }).catch(e => {
                this.setState({error: "Query failed", querying: false});
            });
        }
    }
    queryPoint = (props) => {
        if (props.clickFeature && props.clickFeature.feature === 'searchmarker' && props.clickFeature.geometry) {
            if (this.props.clickFeature !== props.clickFeature)
            {
                return props.clickFeature.geometry;
            }
        }

        if (props.point && props.point.button === 0 && props.point.coordinate) {
            if (!this.props.point.coordinate ||
                this.props.point.coordinate[0] !== props.point.coordinate[0] ||
                this.props.point.coordinate[1] !== props.point.coordinate[1] )
            {
                return props.point.coordinate;
            }
        }
        return null;
    }
    onTaskBarHide = () => {
        this.props.removeLayer("bbtdistances");
        this.setState({data: null, querying: false, error: ""});
        this.props.setCurrentTask(null);
    }
    onWindowClose = () => {
        this.props.removeLayer("bbtdistances");
        this.setState({data: null, querying: false, error: ""});
    }
    render() {
        let result = [
            (
                <TaskBar key="TaskBar" task="BBTDistanceTool" onHide={this.onTaskBarHide}>
                    {() => ({
                        body: this.renderTaskbarBody()
                    })}
                </TaskBar>
            )
        ];

        if(this.state.querying || this.state.data || this.state.error) {
            result.push((
                <ResizeableWindow key="ResultDialog" title={"bbtdistancetool.title"} icon="info-sign" onClose={this.onWindowClose} initialWidth={480} initialHeight={320}>
                    <div role="body" className="bbtdistancetool-body">
                        {this.state.querying ? this.renderWait() : this.state.error ? this.renderError() : this.renderResult()}
                    </div>
                </ResizeableWindow>
            ))
        }
        return result;
    }
    renderTaskbarBody = () => {
        return (
            <div>
                <div className="bbtdistances-taskbar-hint">
                    <Message msgId="bbtdistancetool.clickhelp" />
                </div>
            </div>
        )
    }
    renderWait = () => {
        return (
            <span className="bbtdistancetool-loading">
                <Spinner className="spinner" />
                <Message msgId="bbtdistancetool.loading" />
            </span>
        );
    }
    renderError = () => {
        return (
            <div className="bbtdistancetool-error">
                <Message msgId="bbtdistancetool.error" />
                <br />
                {this.state.error}
            </div>
        );
    }
    renderResult = () => {
        return (
            <div>
                <div className="bbtdistancetool-dialog-intro">
                    {(isEmpty(this.state.data.results['tunnels']) && isEmpty(this.state.data.results['drills'])) ? (
                        <Message msgId="bbtdistancetool.dialognoinfo" />
                    ) : (
                        <Message msgId="bbtdistancetool.dialogintro" />
                    )}
                </div>
                {/* {!isEmpty(this.state.data.results['tunnels']) ? (<h2><Message msgId="bbtdistancetool.tunneldist" /></h2>) : null} */}
                {this.renderTable('tunnels')}
                {!isEmpty(this.state.data.results['drills']) ? (<h2><Message msgId="bbtdistancetool.drilldist" /></h2>) : null}
                {this.renderTable('drills')}
            </div>
        );
    }
    renderTable = (type) => {
        let data = this.state.data.results[type];
        if(isEmpty(data)) {
            return null;
        }
        return (
            <table className="bbtdistancetool-results">
                <tbody>
                    <tr>
                        <th></th>
                        <th>{<Message msgId="bbtdistancetool.tunnel_id" />}</th>
                        <th>{<Message msgId="bbtdistancetool.tunnel_name" />}</th>
                        <th>{<Message msgId="bbtdistancetool.dist" />} [m]</th>
                        <th>{<Message msgId="bbtdistancetool.dist_horiz" />} [m]</th>
                        <th>{<Message msgId="bbtdistancetool.dist_vert" />} [m]</th>
                    </tr>
                    {data.map((result, idx) => (
                        <tr key={"row" + idx} onMouseEnter={() => this.hoverFeature(type, idx)} onMouseLeave={() => this.unhoverFeature(type, idx)}>
                            <td><div style={{display: 'inline-block', width: '32px', height: '4px', backgroundColor: 'rgb(' + result.color.join(',') + ')'}} /></td>
                            <td>{result.tl_id}</td>
                            <td>{result[this.extractTunnelNameKey()]}</td>
                            <td>{result.d.toFixed(0)}</td>
                            <td>{result.d_horiz.toFixed(0)}</td>
                            <td>{result.d_vert.toFixed(0)}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        )
    }
    extractTunnelNameKey = () => {
        const lang = LocaleUtils.normalizeLocaleCode(LocaleUtils.getUserLocale())
        return `name_${lang}`
    }
    drawMeasurementLines = (data) => {
        const layer = {
            id: "bbtdistances"
        };
        let features = [];
        let extent = [data.point.x, data.point.y, data.point.x, data.point.y];
        for(let type of ["tunnels", "drills"]) {
            features = features.concat((data.results[type] || []).map((result, idx) => {
                extent[0] = Math.min(extent[0], result.p_x);
                extent[1] = Math.min(extent[1], result.p_y);
                extent[2] = Math.max(extent[2], result.p_x);
                extent[3] = Math.max(extent[3], result.p_y);
                return {
                    geometry: {type: 'LineString', coordinates: [
                        [data.point.x, data.point.y],
                        [result.p_x, result.p_y]
                    ]},
                    id: 'line' + idx,
                    crs: this.props.mapCrs,
                    styleName: 'default',
                    styleOptions: {
                        strokeColor: result.color,
                        strokeWidth: 2,
                        strokeDash: type == "tunnels" ? [] : [5, 5]
                    }
                };
            }));
        }
        features.push({
            id: "clickmarker",
            geometry: {
                type: 'Point',
                coordinates: [data.point.x, data.point.y]
            },
            crs: this.props.mapCrs,
            styleName: 'marker'
        });
        // Padd extent by 50px
        let resolution = MapUtils.computeForZoom(this.props.resolutions, this.props.mapZoom);
        // 50px / 72 dpi * 0.0254 in/m
        let padding = 50 /72 * 0.0254 * resolution
        // Pad extent by 10%
        let width = extent[2] - extent[0];
        let height = extent[3] - extent[1];
        extent[0] -= 0.05 * width;
        extent[2] += 0.05 * width;
        extent[1] -= 0.05 * height;
        extent[3] += 0.05 * height;
        this.props.zoomToExtent(extent, this.props.mapCrs);
        this.props.addLayerFeatures(layer, features, true);
    }
    hoverFeature = (type, idx) => {
        const layer = {
            id: "bbtdistances"
        };
        let result = this.state.data.results[type][idx];
        let feature = {
            geometry: {type: 'LineString', coordinates: [
                [this.state.data.point.x, this.state.data.point.y],
                [result.p_x, result.p_y]
            ]},
            id: 'highlight' + type + idx,
            crs: this.props.mapCrs,
            styleName: 'default',
            styleOptions: {
                strokeColor: [255, 255, 0],
                strokeWidth: 4,
                strokeDash: []
            }
        };
        this.props.addLayerFeatures(layer, [feature], false);
    }
    unhoverFeature = (type, idx) => {
        this.props.removeLayerFeatures("bbtdistances", ['highlight' + type + idx]);
    }
};

module.exports = {
    BBTDistanceToolPlugin: connect((state) => ({
        point: state.map && state.map.clickPoint || {},
        clickFeature: state.map.clickFeature || {},
        mapCrs: state.map.projection,
        mapZoom: state.map.zoom,
        resolutions: state.map.resolutions,
        enabled: state.task.id === "BBTDistanceTool"
    }), {
        setCurrentTask: setCurrentTask,
        removeLayer: removeLayer,
        addLayerFeatures: addLayerFeatures,
        removeLayerFeatures: removeLayerFeatures,
        zoomToExtent: zoomToExtent
    })(BBTDistanceTool),
    reducers: {}
};
