const React = require("react");
const { connect } = require("react-redux");
const ConfigUtils = require("qwc2/utils/ConfigUtils");
const PropTypes = require("prop-types");
const { TaskBar } = require("qwc2/components/TaskBar");
const Message = require("qwc2/components/I18N/Message");

class ChangeLanguageTool extends React.Component {
    static propTypes = {
        enabled: PropTypes.bool,
    };
    state = {
        aviableLanguages: ConfigUtils.getConfigProp("changeLanguage") ?? [],
        currentLang: null,
        positionList: {
            x: 0,
            y: 0,
        },
    };
    calculateCurrentLang = () => {
        const currentPath = document.location.pathname;
        const currentLanguageConfig = this.state.aviableLanguages.find(
            ({ path }) => path === currentPath
        );
        if (currentLanguageConfig) {
            this.setState({ currentLang: currentLanguageConfig });
        } else {
            console.error(
                `ChangeLanguageTool: Unable to locate the current laguage for config`,
                this.state.aviableLanguages
            );
        }
    };
    setPositionList = () => {
        const ID_ICON = "ChangeLanguageTool";
        const icon = document.getElementById(ID_ICON);
        if (icon) {
            const iconRect = icon.getBoundingClientRect();
            this.setState({
                positionList: {
                    x: iconRect.left,
                    y: iconRect.top + iconRect.height,
                },
            });
        } else {
            console.error(
                `ChangeLanguageTool: Unable to locate icon for language tool with id ${ID_ICON} have you change the id in config?`
            );
        }
    };
    componentWillReceiveProps(newProps) {
        this.setPositionList();
        this.calculateCurrentLang();
        if (this.props.enabled === true && newProps.enabled === false) {
            this.setState({ open: false });
        }
        if (this.props.enabled === false && newProps.enabled === true) {
            this.setState({ open: true });
        }
    }
    isSelectedLanguage = (language) => {
        return this.state.currentLang.key === language.key;
    };
    render() {
        if (this.props.enabled) {
            return (
                <div
                    style={{
                        position: "absolute",
                        zIndex: 1000,
                        top: this.state.positionList.y,
                        left: this.state.positionList.x,
                        width: 50,
                        backgroundColor: "rgba(255, 255, 255, 0.95)",
                        boxShadow: "0px 2px 4px rgba(136, 136, 136, 0.5)",
                        display: "flex",
                        flexWrap: "wrap",
                    }}
                >
                    {this.state.aviableLanguages.map((language) => (
                        <div
                            key={language.key}
                            style={{
                                width: "100%",
                                padding: 10,
                                textAlign: "center",
                                backgroundColor: this.isSelectedLanguage(
                                    language
                                )
                                    ? "white"
                                    : "#f2f2f2",
                                border: this.isSelectedLanguage(language)
                                    ? "0.5px solid black"
                                    : "none",
                            }}
                        >
                            <a
                                style={{ color: "#595959" }}
                                href={language.path}
                            >
                                {language.label}
                            </a>
                        </div>
                    ))}
                </div>
            );
        } else {
            return null;
        }
    }
}

module.exports = {
    ChangeLanguageToolPlugin: connect(
        (state) => ({
            enabled: state.task.id === "ChangeLanguageTool",
        }),
        {}
    )(ChangeLanguageTool),
    reducers: {},
};
