/**
 * Copyright 2016, Sourcepole AG.
 * All rights reserved.
 *
 * This source code is licensed under the BSD-style license found in the
 * LICENSE file in the root directory of this source tree.
 */

const React = require('react');
const PropTypes = require('prop-types');
const {connect} = require('react-redux');
const Icon = require('qwc2/components/Icon');
require('qwc2/plugins/style/Buttons.css');

class ScreenshotTool extends React.Component {
    static propTypes = {
        position: PropTypes.number,
    }
    static defaultProps = {
        position: 6
    }
    static contextTypes = {
        messages: PropTypes.object
    }
    render() {
        return (
            <button className="map-button"
                onClick={ev => this.screenshot()}
                style={{bottom: (5 + 4 * this.props.position) + 'em'}}
            >
                <Icon icon="screen-download"/>
            </button>
        );
    }
    screenshot() {
        let canvas = document.createElement("canvas");;

        // Request media
        navigator.mediaDevices.getDisplayMedia({preferCurrentTab: true}).then(stream => 
        {
            // Grab frame from stream
            let track = stream.getVideoTracks()[0];
            let capture = new ImageCapture(track);
            capture.grabFrame().then(bitmap => 
            {
                // Stop sharing
                track.stop();
            
                // Draw the bitmap to canvas
                canvas.width = bitmap.width;
                canvas.height = bitmap.height;
                canvas.getContext('2d').drawImage(bitmap, 0, 0);
                
                // Grab blob from canvas
                canvas.toBlob(blob => {
                    // Do things with blob here

                    let a = document.createElement("a");
                    document.body.appendChild(a);
                    a.style = "display: none";
                    let url = window.URL.createObjectURL(blob);
                    a.href = url;
                    a.download = "screenshot.png";
                    a.click();
                    window.URL.revokeObjectURL(url);
                });
            });
        })
        .catch(e => console.log(e));
    }
};

module.exports = {
    ScreenshotToolPlugin: connect(
    )(ScreenshotTool),
    reducers: {}
};
