const React = require("react");
const { connect } = require("react-redux");
const PropTypes = require("prop-types");
const { setCurrentTask } = require('qwc2/actions/task');
const ResizeableWindow = require("qwc2/components/ResizeableWindow");
const ConfigUtils = require("qwc2/utils/ConfigUtils");
const Message = require("qwc2/components/I18N/Message");
require('./style/InfoLegendTool.css');


class InfoLegendTool extends React.Component {
    static propTypes = {
        enabled: PropTypes.bool,
        openAtStartup: PropTypes.bool,
    };
    state = {};
    constructor(props) {
        super(props);
        if (props.openAtStartup) {
            props.setCurrentTask('InfoLegendTool');
        }
    }
    onWindowClose = () => {
        this.props.setCurrentTask(null);
    }
    render() {
        let assetsPath = ConfigUtils.getConfigProp("assetsPath");
        if (this.props.enabled) {
            return (
                <ResizeableWindow title={"infolegendtool.title"} icon="info-sign" onClose={this.onWindowClose} initialX={0} initialY={window.innerHeight - 320 - 50 - 30} initiallyDocked={this.props.initiallyDocked} initialWidth={480} initialHeight={320}>
                    <div className="legend-wrapper" role="body" ref={el => { if (el) el.style.background = 'inherit'; }}>
                        <h4><Message msgId="infolegendtool.legend" /></h4>
                        <ul className="list-legend">
                            <li className="color-line main-tunnels"><Message msgId="infolegendtool.mainTunnels" /></li>
                            <li className="color-line exploratory-tunnel"><Message msgId="infolegendtool.exploratoryTunnel" /></li>
                            <li className="color-line south-interconnection-tunnel"><Message msgId="infolegendtool.southInterconnectionTunnel" /></li>
                            <li className="color-line innsbruck-bypass"><Message msgId="infolegendtool.innsbruckBypass" /></li>
                            <li className="color-line other-tunnels"><Message msgId="infolegendtool.otherTunnels" /></li>
                            <li className="color-line excavation-progresses"><Message msgId="infolegendtool.excavationProgresses" /></li>
                            <li style={{ "backgroundImage": `url(${assetsPath + '/img/ic-avanzamento-scavi.svg'})` }} className="color-line excavation-fronts"><Message msgId="infolegendtool.excavationFronts" /></li>
                            <li className="color-line brenner-railway"><Message msgId="infolegendtool.brennerRailway" /></li>
                            <li className="color-line state-border-italy-austria"><Message msgId="infolegendtool.stateBorderItalyAustria" /></li>
                        </ul>
                    </div>
                </ResizeableWindow>
            );
        } else {
            return null;
        }
    }
}

module.exports = {
    InfoLegendToolPlugin: connect(
        (state) => ({
            enabled: state.task.id === "InfoLegendTool",
        }),
        { setCurrentTask: setCurrentTask }
    )(InfoLegendTool),
    reducers: {},
};
